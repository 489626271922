import { css, Paper, styled } from '@mui/material';
import { Link } from '../ui';

export const StyledPartnerInfoContainer = styled('div')(
  ({ theme }) => css`
    h1 {
      margin-bottom: ${theme.spacing('LARGE')};
    }
      p {
      margin-bottom: ${theme.spacing('1XL')};

  `
);
export const StyledPartnerCard = styled(Paper)`
  padding: 0 !important;
  display: flex;
  cursor: pointer;
`;
export const StyledPartnerCardInfo = styled('div')(
  ({ theme }) => css`
    padding-top: ${theme.spacing('SMALL')};
    padding-bottom: ${theme.spacing('SMALL')};
    padding-left: ${theme.spacing('MEDIUM')};
    padding-right: ${theme.spacing('MEDIUM')};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `
);
export const StyledPartnerLink = styled(Link)`
  &::after {
    background-color: transparent !important;
  }
`;
