import {
  imgLinks,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../shared';
import {
  claimsReportLink,
  daDirektReferralLinkDental,
  daDirektReferralLinkPet,
  dentistsMapLink,
  onlineVetsLink,
  referralLink,
  useClaimsInfoLink,
  useHealthPartnerLink,
} from './navMenuConfig';

const getDentalNavMenuLinks = (isAmtrustCustomer, isDaDirektCustomer) => {
  return isAmtrustCustomer
    ? [useClaimsInfoLink, dentistsMapLink]
    : [
        useClaimsInfoLink,
        dentistsMapLink,
        isDaDirektCustomer ? daDirektReferralLinkDental : referralLink,
      ];
};

const getPetNavMenuLinks = (
  customerInsuranceCategory,
  isDaDirektCustomer,
  isSpecialTariffCustomer,
  isPatolo
) => {
  if (customerInsuranceCategory === INSURANCE_CATEGORY_PET_LIABILITY) {
    return [claimsReportLink];
  }

  if (isPatolo) {
    // hide referal and online vet for patolo contracts

    return [useClaimsInfoLink];
  }
  return isSpecialTariffCustomer
    ? [useClaimsInfoLink, onlineVetsLink]
    : [
        useClaimsInfoLink,
        onlineVetsLink,
        isDaDirektCustomer ? daDirektReferralLinkPet : referralLink,
      ];
};

const getHealthNavMenuLinks = (isDaDirektCustomer) => {
  return isDaDirektCustomer
    ? [useClaimsInfoLink, useHealthPartnerLink]
    : [useClaimsInfoLink, useHealthPartnerLink, referralLink];
};

export const getMenuLinksConfig = (
  insuranceType,
  customerInsuranceCategory,
  isDaDirektCustomer,
  isAmtrustCustomer,
  isSpecialTariffCustomer,
  isPatolo
) => {
  const getNavMenuLinks = {
    [INSURANCE_TYPE_DENTOLO]: getDentalNavMenuLinks(isAmtrustCustomer, isDaDirektCustomer),
    [INSURANCE_TYPE_PETOLO]: getPetNavMenuLinks(
      customerInsuranceCategory,
      isDaDirektCustomer,
      isSpecialTariffCustomer,
      isPatolo
    ),
    [INSURANCE_TYPE_VITOLO]: getHealthNavMenuLinks(isDaDirektCustomer),
    default: [useClaimsInfoLink, dentistsMapLink, referralLink],
  };

  const navMenuLinks = getNavMenuLinks[`${insuranceType}`] || getNavMenuLinks.default;

  return navMenuLinks;
};

export const getInsCategoryImg = (insCategory) => {
  let img = 'icon.menu.green.tooth';
  if (
    insCategory === INSURANCE_CATEGORY_PET_HEALTH ||
    insCategory === INSURANCE_CATEGORY_PET_LIABILITY
  ) {
    img = 'icon.menu.orange.paw';
  }

  if (insCategory === INSURANCE_CATEGORY_DENTAL) {
    img = 'icon.menu.green.tooth';
  }

  if (insCategory === INSURANCE_CATEGORY_HEALTH) {
    img = 'icon.vitolo.heart.violet';
  }

  return imgLinks[`${img}`];
};
