import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import {
  DA_DIREKT_REFERRAL_PAGE_LINK_NAV_DENTAL,
  DA_DIREKT_REFERRAL_PAGE_LINK_NAV_PET,
  DENTOLO_HELP_LINK,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_VITOLO,
  PETOLO_HELP_LINK,
  VITOLO_HELP_LINK,
  imgLinks,
} from '../../shared';

import IconExternalLink from '../Icons/IconExternalLink';

export const miscellaneousLinkItems = [
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.help_center" />
      </Typography>
    ),
    link: DENTOLO_HELP_LINK,
    externalUrl: true,
    icon: imgLinks['icon.menu.headphone'],
  },
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.contacts" />
      </Typography>
    ),
    link: '/contact',
    externalUrl: false,
    icon: imgLinks['icon.menu.phone'],
  },
];

export const petHelpLinkItems = [
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.help_center" />
      </Typography>
    ),
    link: PETOLO_HELP_LINK,
    externalUrl: true,
    icon: imgLinks['icon.menu.headphone'],
  },
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.contacts" />
      </Typography>
    ),
    link: '/contact',
    externalUrl: false,
    icon: imgLinks['icon.menu.phone'],
  },
];
export const patoloHelpLinkItems = [
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.contacts" />
      </Typography>
    ),
    link: '/contact',
    externalUrl: false,
    icon: imgLinks['icon.menu.phone'],
  },
];

export const vitoloHelpLinkItems = [
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.help_center" />
      </Typography>
    ),
    link: VITOLO_HELP_LINK,
    externalUrl: true,
    icon: imgLinks['icon.menu.headphone'],
  },
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.contacts" />
      </Typography>
    ),
    link: '/contact',
    externalUrl: false,
    icon: imgLinks['icon.menu.phone'],
  },
];

export const myProfileLinkItems = [
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.dashboard" />
      </Typography>
    ),
    link: '/dashboard',
    externalUrl: false,
    icon: '/images/navbar/dashboard.svg',
  },
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.tarif" />
      </Typography>
    ),
    link: '/policy-info',
    externalUrl: false,
    icon: '/images/navbar/tarifdetails.svg',
  },
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.contact_details" />
      </Typography>
    ),
    link: '/user-info',
    externalUrl: false,
    icon: '/images/navbar/contract_management.svg',
  },
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.docs" />
      </Typography>
    ),
    link: '/contract-docs',
    externalUrl: false,
    icon: '/images/navbar/contract_documents.svg',
  },
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.premiums" />
      </Typography>
    ),
    link: '/payment-details',
    externalUrl: false,
    icon: '/images/navbar/insurance_premiums.svg',
  },
  {
    text: (
      <Typography variant="p">
        <FormattedMessage id="menu.header.item.contribution_statement" />
      </Typography>
    ),
    link: '/payment-overview',
    externalUrl: false,
    icon: '/images/navbar/contribution_confirmation.svg',
    displayForInsuranceTypes: [INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_VITOLO],
    iconSpacing: true,
  },
];

export const useClaimsInfoLink = {
  path: '/user-claims-info',
  title: (
    <Typography variant="h3">
      <FormattedMessage id="menu.header.item.reimbursement" />
    </Typography>
  ),
};

export const useHealthPartnerLink = {
  path: '/health-partner-info',
  title: (
    <Typography variant="h3">
      <FormattedMessage id="menu.header.item.partner" />
    </Typography>
  ),
};
export const claimsReportLink = {
  path: '/user-claims-info',
  title: (
    <Typography variant="h3">
      <FormattedMessage id="menu.header.item.claim_report" />
    </Typography>
  ),
};

export const dentistsMapLink = {
  path: '/dentists-map',
  title: (
    <Typography variant="h3">
      <FormattedMessage id="menu.header.item.find_dentist" />
    </Typography>
  ),
};

export const onlineVetsLink = {
  path: '/online-vets',
  title: (
    <Typography variant="h3">
      <FormattedMessage id="menu.header.item.find_vet" />
    </Typography>
  ),
};

export const referralLink = {
  path: '/referral',
  title: (
    <Typography variant="h3">
      <FormattedMessage id="menu.header.item.referrals" />
    </Typography>
  ),
};

export const contactLink = {
  path: '/contact',
  title: (
    <Typography variant="p">
      <FormattedMessage id="menu.header.item.contacts" />
    </Typography>
  ),
};

export const daDirektReferralLinkDental = {
  title: (
    <>
      <Typography variant="h3">
        <FormattedMessage id="menu.header.item.referrals" />
      </Typography>
      <IconExternalLink />
    </>
  ),
  link: DA_DIREKT_REFERRAL_PAGE_LINK_NAV_DENTAL,
  externalUrl: true,
};

export const daDirektReferralLinkPet = {
  title: (
    <>
      <Typography variant="h3">
        <FormattedMessage id="menu.header.item.referrals" />
      </Typography>
      <IconExternalLink />
    </>
  ),
  link: DA_DIREKT_REFERRAL_PAGE_LINK_NAV_PET,
  externalUrl: true,
};
