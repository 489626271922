import { styled, css, Container } from '@mui/material';

import { IconButton, BackButton } from '../common';

export const StyledContainer = styled(Container)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;
export const StyledSundayPartnerHeader = styled('div')(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    margin-bottom: ${theme.spacing('1XL')};

    ${theme.breakpoints.up('desktop')} {
      position: relative;

      top: 0;
      left: 0;
      height: 35rem;
      overflow: hidden;
      width: auto;
    }
  `
);
export const StyledOnlineVetCoverImage = styled('img')(
  ({ theme }) => css`
    width: 100%;
    max-width: 100%;
    height: auto;
    ${theme.breakpoints.up('desktop')} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.65);
      width: auto;
      max-width: unset;

      height: 54rem;
      object-fit: cover;
    }
  `
);

export const StyledContentTextHeader = styled('div')(
  ({ theme }) => css`
    span {
      display: inline-block;
    }
    p {
      margin-top: ${theme.spacing('2XL')};
      margin-bottom: ${theme.spacing('LARGE')};
      ${theme.breakpoints.up('desktop')} {
        margin-top: ${theme.spacing('1XL')};
      }
    }
  `
);

export const StyledVoucherContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.palette.gray.ultra_light_gray};
    border-color: ${theme.palette.main.product_blue};
    border-radius: ${theme.spacing('XSMALL')};
    padding: ${theme.spacing('SMALL')};
    align-items: center;
    margin: auto;
    margin-top: ${theme.spacing('LARGE')};
    margin-bottom: ${theme.spacing('LARGE')};
    border: 1px dashed;
    row-gap: ${theme.spacing('MEDIUM')};
    ${theme.breakpoints.up('desktop')} {
      row-gap: 0.375rem;
    }
  `
);

export const StyledVoucherCopyContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    column-gap: ${theme.spacing('SMALL')};
    align-items: center;

    &:focus {
      box-shadow: ${theme.shadows[3]};
    }
    p {
      margin-bottom: ${theme.spacing('MEDIUM')};
    }
  `
);

export const StyledIconButton = styled(IconButton)`
  img {
    width: auto;
    height: auto;
  }
  width: auto;
  height: auto;
`;

export const StyledSundayFlexContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    > button {
      min-width: auto !important;
      margin-bottom: ${theme.spacing('2XL')};
      ${theme.breakpoints.up('desktop')} {
      margin-bottom: ${theme.spacing('1XL')};

    }
  `
);

export const StyledSectionContainer = styled('div')(
  ({ theme }) => css`
    h2 {
      margin-bottom: ${theme.spacing('XSMALL')};
    }
    p {
      margin-bottom: ${theme.spacing('2XL')};
    }
    ${theme.breakpoints.up('desktop')} {
      h2 {
        margin-bottom: ${theme.spacing('LARGE')};
      }
      p {
        margin-bottom: ${theme.spacing('1XL')};
      }
    }
  `
);
export const StyledBulletList = styled('ul')(
  ({ theme }) => css`
    padding-left: ${theme.spacing('1XL')};
    list-style-type: disc;
    margin: 0;

    p {
      margin: 0;
    }
  `
);
export const StyledBackButton = styled(BackButton)(
  ({ theme }) => css`
    z-index: 1;
    position: absolute;
    top: 2rem;

    margin: auto;
    width: 100%;
    padding: 0rem;
    ${theme.breakpoints.up('desktop')} {
      position: relative;
      top: 4.0625rem;
      display: flex;
      width: 43.75rem;
    }
  `
);
