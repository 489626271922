import React from 'react';

import { useMediaQuery, useTheme, Container, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
  StyledPartnerInfoContainer,
  StyledPartnerCard,
  StyledPartnerCardInfo,
  StyledPartnerLink,
} from './styled/HealthPartnerPage';
import { imgLinks } from '../shared';

const HealthPartnerPage = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  const getSundayCardImage = () => {
    return isSmScreen ? imgLinks['sunday-icon-sm'] : imgLinks['sunday-icon-lg'];
  };
  return (
    <Container>
      <StyledPartnerInfoContainer>
        <Typography variant="h1">
          <FormattedMessage id="vitolo.partner.page.title" />
        </Typography>
        <Typography variant="p">
          <FormattedMessage id="vitolo.partner.page.description" />
        </Typography>
      </StyledPartnerInfoContainer>
      <StyledPartnerLink variant="nostyle" to="/sunday-partner-info">
        <StyledPartnerCard>
          <img src={getSundayCardImage()} alt="placeholder" />
          <StyledPartnerCardInfo>
            <Typography variant="h3">
              <FormattedMessage id="vitolo.partner.page.sunday.card.title" />
            </Typography>
            <Typography variant="p">
              <FormattedMessage id="vitolo.partner.page.sunday.card.description" />
            </Typography>
          </StyledPartnerCardInfo>
        </StyledPartnerCard>
      </StyledPartnerLink>
    </Container>
  );
};

export default HealthPartnerPage;
