import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery, useTheme, Button, Typography } from '@mui/material';
import ReactTooltip from 'react-tooltip';

import { imgLinks, useReady } from '../../shared';
import useConsole from '../../hooks/useConsole';

import {
  StyledSundayPartnerHeader,
  StyledSundayFlexContainer,
  StyledOnlineVetCoverImage,
  StyledContentTextHeader,
  StyledVoucherContainer,
  StyledVoucherCopyContainer,
  StyledIconButton,
  StyledContainer,
  StyledSectionContainer,
  StyledBulletList,
  StyledBackButton,
} from './SundayPartner.styled';

const SundayPartner = () => {
  const ready = useReady();
  const sundayPartnerHomeLink = 'https://www.sunday.de/vitolo';
  const textRef = useRef(null);
  const buttonRef = useRef(null);
  const { consoleError } = useConsole();
  const intl = useIntl();

  const theme = useTheme();

  const isSmScreen = useMediaQuery(theme.breakpoints.down('tablet'));
  const isMdScreen = useMediaQuery(theme.breakpoints.between('tablet', 'desktop'));
  const isLgScreen = useMediaQuery(theme.breakpoints.between('desktop', 'largeDesktop'));
  const isXlScreen = useMediaQuery(theme.breakpoints.up('largeDesktop'));

  const getSundayHeaderImg = () => {
    switch (true) {
      case isXlScreen:
        return imgLinks['sunday-header-xl'];
      case isLgScreen:
        return imgLinks['sunday-header-lg'];
      case isMdScreen:
        return imgLinks['sunday-header-md'];
      case isSmScreen:
        return imgLinks['sunday-header-sm'];
      default:
        return imgLinks['sunday-header-lg'];
    }
  };

  const handleCopy = () => {
    if (!window?.navigator?.clipboard) return;

    const textToCopy = textRef.current?.innerText || '';
    if (!textToCopy) return;
    window.navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Use buttonRef.current directly:
        ReactTooltip.show(buttonRef.current);
        setTimeout(() => {
          ReactTooltip.hide(buttonRef.current);
        }, 1000);
      })
      .catch((err) => consoleError('Copy failed', err));
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleCopy();
    }
  };
  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <>
        <StyledSundayPartnerHeader>
          <StyledBackButton
            to="/health-partner-info"
            label={<FormattedMessage id="common.button.back" />}
          />

          <StyledOnlineVetCoverImage src={getSundayHeaderImg()} alt="sundayHeaderImage" />
        </StyledSundayPartnerHeader>
        <StyledContainer>
          <StyledContentTextHeader>
            <Typography variant="h2" component="span">
              {intl.formatMessage(
                { id: 'vitolo.partner.page.sunday.title1' },
                {
                  percentage: (
                    <Typography variant="h2" component="span" color="lavenderIndigo">
                      <FormattedMessage id="vitolo.partner.page.sunday.title2" />
                    </Typography>
                  ),
                }
              )}
            </Typography>
            <Typography variant="p">
              <FormattedMessage id="vitolo.partner.page.sunday.description" />
            </Typography>
          </StyledContentTextHeader>

          <Typography variant="h2">
            <FormattedMessage id="vitolo.partner.page.sunday.voucher.placeholder" />
          </Typography>
          <StyledVoucherContainer>
            <Typography variant="p">
              <FormattedMessage id="vitolo.partner.page.sunday.voucher.label" />
            </Typography>
            <StyledVoucherCopyContainer role="button" tabIndex={0} onKeyDown={handleKeyDown}>
              <Typography variant="h3" ref={textRef}>
                <FormattedMessage id="vitolo.partner.page.sunday.voucher" />
              </Typography>
              <ReactTooltip
                id="copyTooltip"
                place="top"
                effect="solid"
                className="u3-referral-tooltip"
              >
                <Typography variant="p">
                  <FormattedMessage id="vitolo.partner.page.link.copied" />
                </Typography>
              </ReactTooltip>
              <StyledIconButton
                icon="copy.insurance.number"
                data-tip
                data-for="copyTooltip"
                data-event="none"
                ref={buttonRef}
                onClick={handleCopy}
              />
            </StyledVoucherCopyContainer>
          </StyledVoucherContainer>

          <StyledSundayFlexContainer>
            <Button fullWidth onClick={() => window.open(sundayPartnerHomeLink, '_blank')}>
              <Typography variant="buttons">
                <FormattedMessage id="vitolo.partner.page.sunday.home.link" />
              </Typography>
            </Button>
          </StyledSundayFlexContainer>

          <StyledSectionContainer>
            <Typography variant="h2">
              <FormattedMessage id="vitolo.partner.page.sunday.section1.title" />
            </Typography>
            <Typography variant="p">
              <FormattedMessage id="vitolo.partner.page.sunday.section1.description" />
            </Typography>
          </StyledSectionContainer>

          <StyledSectionContainer>
            <Typography variant="h2">
              <FormattedMessage id="vitolo.partner.page.sunday.section2.title" />
            </Typography>

            <Typography variant="p" component="span">
              {intl.formatMessage(
                { id: 'vitolo.partner.page.sunday.section2.description' },
                {
                  except: (
                    <Typography variant="h3" component="span">
                      <FormattedMessage id="vitolo.partner.page.sunday.section2.except" />
                    </Typography>
                  ),
                }
              )}
            </Typography>

            <StyledBulletList>
              <li>
                <Typography variant="p">
                  <FormattedMessage id="vitolo.partner.page.sunday.section2.item1" />
                </Typography>
              </li>
              <li>
                <Typography variant="p">
                  <FormattedMessage id="vitolo.partner.page.sunday.section2.item2" />
                </Typography>
              </li>
              <li>
                <Typography variant="p">
                  <FormattedMessage id="vitolo.partner.page.sunday.section2.item3" />
                </Typography>
              </li>
            </StyledBulletList>
          </StyledSectionContainer>
        </StyledContainer>
      </>
    </CSSTransition>
  );
};

export default SundayPartner;
